import React, { useEffect } from 'react';
import { Redirect, useLocation } from '@reach/router';
import { pick } from 'ramda';
import { parseISO, formatISO } from 'date-fns';
import { parse } from 'qs';

import {
  useGlobalState,
  useJsonApi,
} from '../../services/GlobalStore/GlobalStore';
import { PRODUCT_TYPE, setAppLocalData } from '../../services/appLocalStorage';
import routes from '../../messages/routes';

import { steps, translateRoute } from '../../utils/routes';
import { FlightType } from '../../models/CustomerFlightModel';
import { FunnelFlight } from '../FlightSearch';
import { PassengerModel } from '../../models/PassengerModel';
import { CustomerModel } from '../../models/CustomerModel';
import { CoBrandingFlightModel } from '../../models/CoBrandingFlight';
import { setCustomerFlight } from '../../services/GlobalStore/reducer';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { PlaceOfJurisdictionModel } from '../../models/PlaceOfJurisdictionModel';

import Loading from '../../components/Loading';

interface CoBrandingModel {
  id: string;
  amount: number;
  problem_type: string;
  customer: CustomerModel;
  passengers: PassengerModel[];
  flights: CoBrandingFlightModel[];
  placeOfJurisdiction: PlaceOfJurisdictionModel;
}

const transformCoBrandingToLocalFlight = (
  flight: CoBrandingFlightModel
): FunnelFlight => {
  return {
    ...flight,
    flight_date: formatISO(parseISO(flight?.departure_datetime), {
      representation: 'date',
    }),
    airline_id: flight?.airline?.id,
    type: FlightType.Booked,
    departure_airport_id: flight?.departureAirport?.id,
    arrival_airport_id: flight?.arrivalAirport?.id,
  };
};

// eslint-disable-next-line no-unused-vars
const CoBranding = ({ path }: { path: string }) => {
  const location = useLocation();
  const cobrandingDossierToken = parse(location?.search.slice(1))?.dossierToken;
  const gcHashkey = parse(location?.search.slice(1))?.hashkey;
  const {
    data,
    loading,
    error,
  }: { data: CoBrandingModel[]; loading: boolean; error: any } = useJsonApi(
    `/dossierTokens/${cobrandingDossierToken}`,
    {
      queryParams: {
        include: [
          'customer',
          'passengers',
          'flights.airline',
          'flights.departureAirport',
          'flights.arrivalAirport',
          'placeOfJurisdiction',
        ].join(),
      },
      headers: {
        'gc-hashkey': gcHashkey,
      },
    },
    []
  );
  const [, dispatch] = useGlobalState<FunnelFlight[]>('customerFlights.booked');
  const [, setFlightDisruptions] = useLocalStorageState('flightDisruptions');
  const [, setPlaceOfJurisdiction] = useLocalStorageState(
    'placeOfJurisdiction'
  );

  const initializeLocalStorage = async () => {
    setAppLocalData(PRODUCT_TYPE, {
      value: 'flight_compensation',
    });
    setAppLocalData('searchFlightForm', {
      value: 'flight_compensation',
    });
    setAppLocalData('cobrandingDossierTokenId', {
      value: data?.[0].id,
    });
    setAppLocalData('compensationCheck', {
      value: {
        passengers: data?.[0]?.passengers?.length || 1,
        email: data?.[0]?.customer.email,
      },
    });
    setAppLocalData('shouldAuthenticate', { value: false });
    setAppLocalData('personalInfo', {
      value: {
        ...pick(
          [
            'birthday',
            'city',
            'email',
            'firstname',
            'lastname',
            'postal',
            'booking_number',
          ],
          data?.[0]?.customer
        ),
        phone: data?.[0]?.customer.mobile || data?.[0]?.customer.phone,
        streetname: data?.[0]?.customer.address,
      },
    });
    data?.[0].flights
      ?.map(transformCoBrandingToLocalFlight)
      .forEach((flight, index) => {
        dispatch(setCustomerFlight(flight, index, FlightType.Booked));
      });
    setFlightDisruptions(() => ({
      flightDisruptions: data?.[0].flights.map(flight => flight?.flight_status),
    }));
    setPlaceOfJurisdiction(() => data?.[0].placeOfJurisdiction);
    return;
  };

  useEffect(() => {
    if (data?.length) {
      initializeLocalStorage();
    }
    return () => {
      if (
        !cobrandingDossierToken ||
        error ||
        (data?.length === 0 && !loading)
      ) {
        // localStorage.clear();
      }
    };
    // eslint-disable-next-line
  }, [data?.length]);

  /**
   * Data is fetched but nothing was found? Go to 404
   */
  if (!cobrandingDossierToken || error || (data?.length === 0 && !loading)) {
    return <Redirect noThrow={true} to={'/404'} />;
  }

  if (!loading && data?.length !== 0) {
    const nextPath = translateRoute([
      '/',
      routes[steps.CongratulationsBasepath],
    ]);
    return <Redirect noThrow={true} to={nextPath} />;
  }

  /**
   * Idle
   */
  return (
    <div
      className="flex items-center align-center justify-center"
      style={{ height: '100vh' }}
    >
      <Loading />
    </div>
  );
};

export default CoBranding;
