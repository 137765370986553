import React from 'react';

import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import { steps } from '../utils/routes';
import CoBranding from '../views/CoBranding';

const CoBrandingPage = () => (
  <PageTransition showStepper={false} hideMenu>
    <CoBranding path={routes[steps.CoBranding]} />
  </PageTransition>
);

CoBrandingPage.propTypes = {};

export default CoBrandingPage;
