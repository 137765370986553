import { FlightsAirlineModel } from './FlightsAirlineModel';
import { FlightsAirportModel } from './FlightsAirportModel';

export enum FlightType {
  Booked = 'booked',
  Alternative = 'alternative',
}
/**
 * Model which describe one customer flight
 * This model represents JSONAPI shape of customerFlight entity - the final
 * model to communicate with backend
 */
export interface CustomerFlightModel {
  id?: string;
  airline?: FlightsAirlineModel;
  arrivalAirport: FlightsAirportModel;
  departureAirport: FlightsAirportModel;
  flight_code?: string;
  flight_date?: string;
  type?: FlightType;
  flight_status?: string;
  missed_flight?: false;
}
